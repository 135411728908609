export const LOGIN_TAB_ID = 1;
export const BASIC_TAB_ID = 2;
export const IDPROOF_TAB_ID = 3;
export const ADDRESS_TAB_ID = 4;
export const CFORM_TAB_ID = 5;
export const OTHERDETAILS_TAB_ID = 6;

export const DRIVING_LICENSE_REGEX = /^([a-zA-Z]{2}[0-9]{13})$/ //15 characters, first two alphabets & 13 digits
export const VOTERS_ID_REGEX = /^([a-zA-Z]{3}[0-9]{7})$/ //10 characters, 3 alphabets frist and 7 digits
export const PASSPORT_ID_REGEX = /^([a-zA-Z]{1}[0-9]{7})$/ //8 characters, 1 alphabets, 7 digits
export const AADHAAR_CARD_REGEX = /^([0-9]{12})$/ //12 digit number

export const API_SUCCESS_IDENTIFIER = 'Success'

export const MANDATORY_FIELD_ERROR = 'This is a mandatory field';

export const MANDATORY_FIELD_RULE = [
  {
    required: true,
    message: MANDATORY_FIELD_ERROR,
  }];

export const ACCEPTED_ID_INDIA = [
  {
    value: 'driving_license',
    label: 'Driving License'
  },
  {
    value: 'aadhaar',
    label: 'Aadhaar Card'
  },
  {
    value: 'voter_id',
    label: 'Voter ID'
  }
]