import React from 'reactn';
import BasicDetails from './BasicDetails';
import IdProofDetails from './IdProofDetails';
import AddressDetails from './AddressDetails';
import OtherDetails from './OtherDetails';
import { Tabs } from 'antd';
import CFormDetailsForm from './CFormDetails';
import { LOGIN_TAB_ID, BASIC_TAB_ID, ADDRESS_TAB_ID, OTHERDETAILS_TAB_ID, IDPROOF_TAB_ID, CFORM_TAB_ID } from '../../utils/constants';
import MobileVerificationFormBind from '../MobileVerification/MobileVerificationForm';

const { TabPane } = Tabs;

class FormContainer extends React.Component {
  render() {
    const activeTab = this.global.activeTab.toString();
    return (
      <div className="form-container">
        <Tabs activeKey={activeTab}>
          <TabPane tab="Verify Mobile" key={LOGIN_TAB_ID}>
            <MobileVerificationFormBind />
          </TabPane>
          <TabPane tab="Basic Details" key={BASIC_TAB_ID}>
            <BasicDetails />
          </TabPane>
          {!this.global.enableCForm && (
            <TabPane tab="ID Proof" key={IDPROOF_TAB_ID}>
              <IdProofDetails />
            </TabPane>
          )}
          {!this.global.enableCForm && (
            <TabPane tab="Address Details" key={ADDRESS_TAB_ID}>
              <AddressDetails />
            </TabPane>
          )}
          {this.global.enableCForm && (
            <TabPane tab="Form C" key={CFORM_TAB_ID}>
              <CFormDetailsForm />
            </TabPane>
          )}

          <TabPane tab="Other Details" key={OTHERDETAILS_TAB_ID}>
            <OtherDetails />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default FormContainer;