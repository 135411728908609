import React from "reactn";
import { Form, Input, Button, Select, Spin } from "antd";
import {
  CFORM_TAB_ID,
  ADDRESS_TAB_ID,
  API_SUCCESS_IDENTIFIER,
  MANDATORY_FIELD_RULE,
} from "../../utils/constants";
import { submitUserData } from "../../utils/Api";
import FormErrorsList from "./FormErrorsList";
import { InfoSources } from "../../utils/InfoSources";
import { withRouter } from "react-router-dom";

const ride = ["Car", "Motorbike", "Bicycle", "NA"];
const Occupation = [
  "Working Professionals",
  "Non Working",
  "Student",
  "Self Employed",
  "Other",
];

class OtherDetails extends React.Component {
  otherDetailsForm = React.createRef();

  state = {
    isError: false,
    formErrors: "",
    loading: false,
    vehicleType: "NA",
    infoSource: "",
    occupation: "Other",
  };
  prepareDataForApi = () => {
    var formData = new FormData();
    let data = {
      mobile_number: this.global.verifiedMobileNumber,
      is_otp_verified: this.global.isMobileOtpVerified,
      verification_code: this.global.verification_code,
      resident_data: {
        basic_details: {},
        kyc_indian: {},
        kyc_others: {},
      },
    };
    data.resident_data.basic_details = {
      ...this.global.basicDetails,
      ...this.global.otherDetails,
    };
    if (this.global.enableCForm) {
      formData.append(
        "passport_front",
        document.getElementById("passport_front").files[0]
      );
      formData.append(
        "passport_back",
        document.getElementById("passport_back").files[0]
      );
      formData.append("photo", document.getElementById("photo").files[0]);
      data.resident_data.kyc_others = { ...this.global.cformDetails };
    } else {
      formData.append(
        "kyc_front",
        document.getElementById("kyc_front").files[0]
      );
      formData.append("kyc_back", document.getElementById("kyc_back").files[0]);
      data.resident_data.kyc_indian = {
        ...this.global.idProofDetails,
        ...this.global.addressDetails,
      };
    }
    formData.append("actual_data", JSON.stringify(data));
    if (navigator)
      formData.append(
        "client_details",
        JSON.stringify({
          appCodeName: navigator.appCodeName,
          appName: navigator.appName,
          appVersion: navigator.appVersion,
          userAgent: navigator.userAgent,
          vendor: navigator.vendor,
          vendorSub: navigator.vendorSub,
          language: navigator.language,
        })
      );
    return formData;
  };
  redirectToThankyouPage = () => {
    this.props.history.push({
      pathname: `/thankyou`,
    });
  };
  handleRideChange = (value) => {
    this.setState({
      vehicleType: value,
    });
  };
  handleOccupationChange = (value) => {
    this.setState({
      occupation: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.otherDetailsForm.current
      .validateFields()
      .then((values) => {
        this.setGlobal({
          otherDetails: values,
        });
        this.setState({
          loading: true,
        });
        let data = this.prepareDataForApi();
        submitUserData(data)
          .then((response) => {
            if (response.data.status === API_SUCCESS_IDENTIFIER) {
              this.setState({ isError: false });
              this.redirectToThankyouPage();
            } else {
              this.setState({
                isError: true,
                formErrors: response.data.errors,
              });
            }
          })
          .catch(() => {})
          .finally(() => {
            this.setState({ loading: false });
          });
      })
      .catch((errInfo) => {
        console.log(errInfo);
      });
  };
  prviousStep = () => {
    if (this.global.enableCForm) {
      this.setGlobal({
        activeTab: CFORM_TAB_ID,
      });
    } else {
      this.setGlobal({
        activeTab: ADDRESS_TAB_ID,
      });
    }
  };
  handleInfoSourceChange = (value) => {
    this.setState({ infoSource: value });
  };
  render() {
    return (
      <div className="other-details-container">
        <Spin spinning={this.state.loading} tip="Please wait...">
          <Form ref={this.otherDetailsForm}>
            <Form.Item
              label="Occupation"
              name="occupation"
              // rules={MANDATORY_FIELD_RULE}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Choose"
                size="large"
                onChange={this.handleOccupationChange}
              >
                {Occupation.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Designation"
              name="designation"
              // rules={MANDATORY_FIELD_RULE}
            >
              <Input placeholder="Designation" size="large" />
            </Form.Item>

            <Form.Item
              label="Current company or college"
              name="company"
              // rules={MANDATORY_FIELD_RULE}
            >
              <Input
                placeholder="Current place of work or study"
                size="large"
              />
            </Form.Item>

            <Form.Item
              label="Where did you hear about us?"
              name="info_source"
              // rules={MANDATORY_FIELD_RULE}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Choose"
                size="large"
                onChange={this.handleInfoSourceChange}
              >
                {InfoSources.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {this.state.infoSource === "Others" && (
              <Form.Item
                label="Please specify"
                name="info_source_others"
                // rules={MANDATORY_FIELD_RULE}
              >
                <Input
                  placeholder="Where did you hear about us?"
                  size="large"
                />
              </Form.Item>
            )}
            <Form.Item
              label="Your ride?"
              name="ride"
              // rules={MANDATORY_FIELD_RULE}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Choose"
                size="large"
                onChange={this.handleRideChange}
              >
                {ride.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {this.state.vehicleType !== "NA" &&
              this.state.vehicleType !== "Bicycle" && (
                <Form.Item
                  label="Vehicle Number"
                  name="vehicle_number"
                  // rules={MANDATORY_FIELD_RULE}
                >
                  <Input placeholder="Vehicle Number" size="large" />
                </Form.Item>
              )}

            <div className="steps-action">
              <Button
                style={{ marginRight: 8 }}
                onClick={() => this.prviousStep()}
                size="large"
              >
                Previous
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                disabled={this.state.loading}
                loading={this.state.loading}
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>

          {/* show form erros */}
          {this.state.isError && (
            <FormErrorsList errors={this.state.formErrors} />
          )}
        </Spin>
      </div>
    );
  }
}

export default withRouter(OtherDetails);
