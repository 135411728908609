import React from 'reactn';
import { Input, Form, Button, Row, Col, Select } from 'antd';
import { OTHERDETAILS_TAB_ID, IDPROOF_TAB_ID, MANDATORY_FIELD_RULE } from '../../utils/constants';
import { IndianStates } from '../../utils/IndianStates';
import { UnionTerritories } from '../../utils/UnionTerritories';

class AddressDetails extends React.Component {

  addressForm = React.createRef()

  handleSubmit = (e) => {
    e.preventDefault()
    this.addressForm.current.validateFields().then(values => {
      this.setGlobal({
        activeTab: OTHERDETAILS_TAB_ID,
        addressDetails: values
      })
    }).catch(errInfo => {
      console.log(errInfo)
    });
  }
  prviousStep = () => {
    this.setGlobal({
      activeTab: IDPROOF_TAB_ID,
    })
  }
  render() {

    return (
      <div className="address-details-container">
        <Form ref={this.addressForm}>
          <Form.Item
            label="Father's Name"
            extra="Please add your Father's full name without salutations (Mr. etc.)"
            name='father_name'
            rules={MANDATORY_FIELD_RULE}
          >
            <Input placeholder="Father's Name" size="large" />
          </Form.Item>

          <Form.Item
            label="Address Line 1"
            name='permanent_address_1'
            rules={MANDATORY_FIELD_RULE}
          >
            <Input placeholder="Address Line 1" size="large" />
          </Form.Item>

          <Form.Item
            label="Address Line 2"
            name='permanent_address_2'
            rules={MANDATORY_FIELD_RULE}
          >
            <Input placeholder="Address Line 2" size="large" />
          </Form.Item>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="Pincode"
                name='pincode'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input type="tel" placeholder="560100" size="large" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label="Landmark"
                name='landmark'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="landmark" size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="City"
                name='city'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="city" size="large" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="State"
                name='state'
                rules={MANDATORY_FIELD_RULE}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="State"
                  size="large"
                >
                  <Select.OptGroup label="States">
                    {IndianStates.map(item => (
                      <Select.Option key={item} value={item}>{item}</Select.Option>
                    ))}
                  </Select.OptGroup>
                  <Select.OptGroup label="Union Territories">
                    {UnionTerritories.map(item => (
                      <Select.Option key={item} value={item}>{item}</Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Col xs={24}>
            <div className="steps-action">
              <Button style={{ marginRight: 8 }} onClick={() => this.prviousStep()} size="large">
                Previous
                </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                onClick={this.handleSubmit}
              >
                Next
                </Button>
            </div>
          </Col>
        </Form>

      </div>
    )
  }
}


export default AddressDetails;