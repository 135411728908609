import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_APIURL,
});

/**
 * login and get otp
 */
export function submitUserData(data) {
	return instance.post('/create-resident', data)
		.then(response => response)
		.catch((error) => {
			return error.response
	});
}

export function changeFormateDate(date){
	var p = date.split(/\D/g)
	return [p[2],p[1],p[0] ].join("/")
}