import { DRIVING_LICENSE_REGEX, AADHAAR_CARD_REGEX, VOTERS_ID_REGEX } from "./constants";

export function validateIdNumber(id_type, value) {
  switch (id_type) {
    case 'driving_license':
      if (!DRIVING_LICENSE_REGEX.test(value)) {
        return false;
      }
      break;
    case 'aadhaar':
      if (!AADHAAR_CARD_REGEX.test(value)) {
        return false;
      }
      break;
    case 'voter_id':
      if (!VOTERS_ID_REGEX.test(value)) {
        return false;
      }
      break;

    default:
      break;
  }
}

// convert date from YYYY-MM-DD to DD-MM-YYYY
export function formatDateString(dateString='') {
  if(dateString !== '') {
    return dateString.split("-").reverse().join("-")
  }
}

export function validateFileExtension(file) {
  const valid = ['image/png', 'image/jpg', 'image/jpeg'];
  if(valid.indexOf(file.type) !== -1)
  {
    return true;
  }
  return false;
}

export function allowOnlyNumbers(event) {
  if (
      event.keyCode === 46 || event.keyCode === 8 ||
      event.keyCode === 9 || event.keyCode === 13 ||
      event.keyCode === 37 || event.keyCode === 38 ||
      event.keyCode === 39 || event.keyCode === 40
    ) {
		// success
	}
	else {
		// Ensure that it is a number and stop the keypress
		if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
			event.preventDefault();
		}
	}
}

export function limitInputLength( length, event) {
  allowOnlyNumbers(event);
  if(event.target.value.length >= length) {
    // Ensure that it is a number and stop the keypress
		if (
      event.keyCode === 46 || event.keyCode === 8 ||
      event.keyCode === 9 || event.keyCode === 13 ||
      event.keyCode === 37 || event.keyCode === 38 ||
      event.keyCode === 39 || event.keyCode === 40
    ) {
		  // success
	  }
    else {
      event.preventDefault();
    }
  }
}

export function validatePhoneNumber(num) {
  if(num.length > 15 || num.length < 8)
    return false;
  return true;
}