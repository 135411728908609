import React from 'reactn';
import Header from '../Header';
import TopBanner from '../TopBanner';
import { LOGIN_TAB_ID } from '../../utils/constants';

class ThanksPage extends React.Component {
  componentDidMount() {
    this.setGlobal({
      enableCForm: false,
      activeTab: LOGIN_TAB_ID
    })
  }
  render() {
    return(
      <div className="thanks-container">
        <Header
          heading={"Thank you!"}
          description={"We have received your request. We will verify the information you've provided and get back to you shortly."}
        />
        <TopBanner />
      </div>
    )
  }
}

export default ThanksPage;