import React from "reactn";
import { Form, Input, Select, Button, Row, Col, DatePicker } from "antd";
import {
  CFORM_TAB_ID,
  IDPROOF_TAB_ID,
  MANDATORY_FIELD_RULE,
} from "../../utils/constants";
import { formatDateString } from "../../utils/GlobalFunctions";

const citizenship = ["india", "other"];

class BasicDetails extends React.Component {
  basicForm = React.createRef();

  state = {
    dob: "",
  };
  onChangeDOB = (date, dateString) => {
    this.setState({ dob: formatDateString(dateString) });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.basicForm.current
      .validateFields()
      .then((values) => {
        values.dob = this.state.dob;
        values.mobile_number = this.global.verifiedMobileNumber;
        if (values.citizenship === "other") {
          this.setGlobal({
            enableCForm: true,
            activeTab: CFORM_TAB_ID,
          });
        } else {
          this.setGlobal({
            enableCForm: false,
            activeTab: IDPROOF_TAB_ID,
          });
        }
        this.setGlobal({
          basicDetails: values,
        });
      })
      .catch((errInfo) => {
        console.log(errInfo);
      });
  };

  componentDidMount() {
    this.basicForm.current.setFields([
      {
        name: "email",
        value: this.global.verifiedEmail,
      },
    ]);
  }

  render() {
    const duration = this.global.dropDownData.duration || [];
    const buildings = this.global.dropDownData.buildings || [];

    return (
      <div className="basic-details-container">
        <Form ref={this.basicForm}>
          <Form.Item
            label="First Name"
            extra="As in your government ID"
            name="first_name"
            rules={MANDATORY_FIELD_RULE}
          >
            <Input placeholder="First Name" size="large" />
          </Form.Item>

          <Form.Item
            label="Middle Name"
            extra="As in your government ID"
            name="middle_name"
          >
            <Input placeholder="Middle Name" size="large" />
          </Form.Item>

          <Form.Item
            label="Last Name"
            extra="As in your government ID"
            name="last_name"
            rules={MANDATORY_FIELD_RULE}
          >
            <Input placeholder="Last Name" size="large" />
          </Form.Item>

          <Form.Item
            label="Email address"
            name="email"
            rules={MANDATORY_FIELD_RULE}
          >
            <Input placeholder="Email address" size="large" className="email" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Date of birth"
                name="dob"
                rules={MANDATORY_FIELD_RULE}
              >
                <DatePicker
                  placeholder="DOB"
                  style={{ width: "100%" }}
                  size="large"
                  format="DD-MM-YYYY"
                  onChange={this.onChangeDOB}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={MANDATORY_FIELD_RULE}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Gender"
                  size="large"
                >
                  <Select.Option value="male">Male</Select.Option>
                  <Select.Option value="female">Female</Select.Option>
                  <Select.Option value="Non binary">Non Binary</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Your Chosen Abode"
              name="property"
              rules={MANDATORY_FIELD_RULE}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Please select one"
                size="large"
              >
                {buildings.map((item) => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Duration"
              name="duration"
              // rules={MANDATORY_FIELD_RULE}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Duration"
                size="large"
              >
                {duration.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Citizenship"
              name="citizenship"
              rules={MANDATORY_FIELD_RULE}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Choose"
                size="large"
              >
                {citizenship.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item.toUpperCase()}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="steps-action">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                onClick={this.handleSubmit}
              >
                Next
              </Button>
            </div>
          </Col>
        </Form>
      </div>
    );
  }
}

export default BasicDetails;
