import React, { setGlobal } from "reactn";
import "./app.scss";
import { LOGIN_TAB_ID } from "../utils/constants";
import Routing from "./Routing";

setGlobal({
  mobileNumberVerified: true,
  verifiedMobileNumber: "",
  verifiedEmail: "",
  isMobileOtpVerified: false,
  verification_code: "",
  enableCForm: false,
  basicDetails: {},
  idProofDetails: {},
  addressDetails: {},
  cformDetails: {},
  otherDetails: {},
  files: {},
  dropDownData: [],
  activeTab: LOGIN_TAB_ID,
});
const App = (props) => {
  return (
    <div className="app">
      <Routing />
      {props.children}
    </div>
  );
};

export default App;
