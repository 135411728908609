/**
 * This component is responsible for the routing of the application
 * If no component assigned for a requested route, 404 page will be returned as the default component
 */
import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import Home from './Home';
import ThanksPage from './Thankyou/ThanksPage';

class Routing extends React.Component {
	render() {
		return (
			<Switch>
				<Route path="/thankyou" component={ThanksPage} />
				<Route path="/" component={Home}/>
			</Switch>
		)
	}
}

export default withRouter(Routing);