import React from 'react';
import Header from "./Header";
import TopBanner from "./TopBanner";
import FormContainer from "./Form/FormContainer";

const Home = () => {
  return (
    <div className="home-container">
      <Header 
        heading={"Welcome aboard!"}
        description={"You are one step away from becoming a part of the Housr community!"}
      />
      <TopBanner />
      <FormContainer />
    </div>
  )
}

export default Home;