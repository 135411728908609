export const UnionTerritories = [
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadar and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
  "Jammu and Kashmir",
]