import React from 'reactn';
import { Form, Button, Input, Select, Row, Col, Radio, DatePicker } from 'antd';
import { BASIC_TAB_ID, OTHERDETAILS_TAB_ID, MANDATORY_FIELD_RULE } from '../../utils/constants';
import { IndianStates } from '../../utils/IndianStates';
import { formatDateString, validateFileExtension, validatePhoneNumber } from '../../utils/GlobalFunctions';
import { UnionTerritories } from '../../utils/UnionTerritories';

class CFormDetails extends React.Component {

  cForm = React.createRef()

  state = {
    nextDestination: '',
    passportIssueDate: '',
    passportExpiryDate: '',
    visaIssueDate: '',
    validity: '',
    arrivingDate: ''
  }
  onChangePassportIssueDate = (date, dateString) => {
    this.setState({ passportIssueDate: formatDateString(dateString) })
  }
  onChangePassportExpiryDate = (date, dateString) => {
    this.setState({ passportExpiryDate: formatDateString(dateString) })
  }
  onChangeVisaIssueDate = (date, dateString) => {
    this.setState({ visaIssueDate: formatDateString(dateString) })
  }
  onChangeArrivingDate = (date, dateString) => {
    this.setState({ arrivingDate: formatDateString(dateString) })
  }
  onChangeValidity = (date, dateString) => {
    this.setState({ validity: formatDateString(dateString) })
  }
  prviousStep = () => {
    this.setGlobal({
      activeTab: BASIC_TAB_ID
    });
  }
  validatePhoneNumber(values) {
    if (!validatePhoneNumber(values.indian_number)) {

      this.cForm.current.setFields([
        {
          name: 'indian_number',
          errors: ['Invalid mobile number']
        }
      ]);

      return false;
    }
    return true;
  }

  validateFiles = (values) => {
    let passport_front = document.getElementById('passport_front').files[0];
    if (!validateFileExtension(passport_front)) {

      this.cForm.current.setFields([
        {
          name: 'passport_front',
          errors: ['Invalid File format']
        }
      ]);

      return false;
    }

    let passport_back = document.getElementById('passport_back').files[0];
    if (!validateFileExtension(passport_back)) {

      this.cForm.current.setFields([
        {
          name: 'passport_back',
          errors: ['Invalid File format']
        }
      ]);

      return false;
    }

    let photo = document.getElementById('photo').files[0];
    if (!validateFileExtension(photo)) {

      this.cForm.current.setFields([
        {
          name: 'photo',
          errors: ['Invalid File format']
        }
      ]);

      return false;
    }
    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.cForm.current.validateFields().then(values => {
      if (this.validatePhoneNumber(values) && this.validateFiles(values)) {
        values.passport_issue_date = this.state.passportIssueDate;
        values.passport_expiry_date = this.state.passportExpiryDate;
        values.validity = this.state.validity;
        values.arriving_date = this.state.arrivingDate;
        values.visa_issue_date = this.state.visaIssueDate;
        this.setGlobal({
          activeTab: OTHERDETAILS_TAB_ID,
          cformDetails: values
        })
      }
    }).catch(errInfo => {
      console.log(errInfo)
    })
  }
  handleNextDestinationChange = (e) => {
    this.setState({ nextDestination: e.target.value })
  }
  render() {

    const special_category = this.global.dropDownData.special_category || [];
    const visa_types = this.global.dropDownData.visa_types || [];

    return (
      <div className="cform-details-container">
        <Form ref={this.cForm}>
          <Form.Item
            label="Passport Number"
            name='passport_number'
            rules={MANDATORY_FIELD_RULE}
          >
            <Input placeholder="Passport Number" size="large" />
          </Form.Item>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Passport issue date"
                name='passport_issue_date'
                rules={MANDATORY_FIELD_RULE}
              >
                <DatePicker placeholder="Passport issue date" style={{ width: '100%' }}
                  size="large"
                  format="DD-MM-YYYY"
                  onChange={this.onChangePassportIssueDate}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Passport expiry date"
                name='passport_expiry_date'
                rules={MANDATORY_FIELD_RULE}
              >
                <DatePicker placeholder="Passport expiry date" style={{ width: '100%' }}
                  size="large"
                  format="DD-MM-YYYY"
                  onChange={this.onChangePassportExpiryDate}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Front two passport pages"
                name='passport_front'
                rules={MANDATORY_FIELD_RULE}
              >
                <input id="passport_front" type="file" size="default" accept="image/* " />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Back two passport pages"
                name='passport_back'
                rules={MANDATORY_FIELD_RULE}
              >
                <input id="passport_back" type="file" size="default" accept="image/* " />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Recent photograph of yourself"
              name='photo'
              rules={MANDATORY_FIELD_RULE}
            >
              <input id="photo" type="file" size="default" accept="image/* " />
            </Form.Item>
          </Col>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Special Category"
                extra="Please select “Others” for General category"
                name='special_category'
                rules={MANDATORY_FIELD_RULE}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Choose"
                  size="large"
                >
                  {special_category.map(item => (
                    <Select.Option key={item} value={item}>{item}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Nationality"
                name='nationality'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="Nationality" size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Col xs={24}>
            <Form.Item
              label="Permanent Address Line 1"
              name='other_permanent_address_1'
              rules={MANDATORY_FIELD_RULE}
            >
              <Input placeholder="Permanent Address 1" size="large" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Permanent Address Line 2"
              name='other_permanent_address_2'
              rules={MANDATORY_FIELD_RULE}
            >
              <Input placeholder="Permanent Address 2" size="large" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Pincode"
              name='other_pincode'
              rules={MANDATORY_FIELD_RULE}
            >
              <Input placeholder="Pincode" size="large" />
            </Form.Item>
          </Col>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="City"
                name='city'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="City" size="large" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label="Country"
                name='country'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="Country" size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="Visa Number"
                name='visa_number'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="Visa Number" size="large" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label="Visa issue date"
                name='visa_issue_date'
                rules={MANDATORY_FIELD_RULE}
              >
                <DatePicker placeholder="Visa issue date" style={{ width: '100%' }}
                  size="large"
                  format="DD-MM-YYYY"
                  onChange={this.onChangeVisaIssueDate}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="City of visa issuance"
                name='visa_issuance_city'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="City of visa issuance" size="large" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label="Visa Validity"
                name='validity'
                rules={MANDATORY_FIELD_RULE}
              >
                <DatePicker placeholder="Passport validity" style={{ width: '100%' }}
                  size="large"
                  format="DD-MM-YYYY"
                  onChange={this.onChangeValidity}
                />
              </Form.Item>
            </Col>
          </Row>

          <Col xs={24}>
            <Form.Item
              label="Visa Type"
              name='visa_type'
              rules={MANDATORY_FIELD_RULE}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Choose"
                size="large"
              >
                {visa_types.map(item => (
                  <Select.Option key={item} value={item}>{item}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="Country of visa issuance"
                name='visa_issuance_country'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="Country of visa issuance" size="large" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label="Country you are arriving from?"
                name='from_country'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="Country you are arriving from?" size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="City you are arriving from?"
                name='from_city'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input placeholder="City you are arriving from?" size="large" />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label="Date of arrival in India"
                name='arriving_date'
                rules={MANDATORY_FIELD_RULE}
              >
                <DatePicker placeholder="Date of arrival in India" style={{ width: '100%' }}
                  size="large"
                  format="DD-MM-YYYY"
                  onChange={this.onChangeArrivingDate}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="Indian phone number"
                name='indian_number'
                rules={MANDATORY_FIELD_RULE}
              >
                <Input type="tel" placeholder="Indian phone number" size="large" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Next Destination"
                name='next_destination'
                rules={MANDATORY_FIELD_RULE}
              >
                <Radio.Group onChange={this.handleNextDestinationChange}>
                  <Radio value='Inside India'>Inside India</Radio>
                  <Radio value='Outside India'>Outside India</Radio>
                </Radio.Group>

              </Form.Item>
            </Col>
          </Row>
          {this.state.nextDestination === 'Inside India' && (
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="State"
                  name='next_state'
                  rules={MANDATORY_FIELD_RULE}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Choose"
                    size="large"
                  >
                    <Select.OptGroup label="States">
                      {IndianStates.map(item => (
                        <Select.Option key={item} value={item}>{item}</Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label="Union Territories">
                      {UnionTerritories.map(item => (
                        <Select.Option key={item} value={item}>{item}</Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>

                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  label="City"
                  name='next_city'
                  rules={MANDATORY_FIELD_RULE}
                >
                  <Input placeholder="City" size="large" />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  label="Place"
                  name='next_place'
                  rules={MANDATORY_FIELD_RULE}
                >
                  <Input placeholder="Place" size="large" />
                </Form.Item>
              </Col>
            </Row>
          )}


          {this.state.nextDestination === 'Outside India' && (
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Country"
                  name='next_country'
                  rules={MANDATORY_FIELD_RULE}
                >
                  <Input placeholder="Country" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="State"
                  name='next_state'
                  rules={MANDATORY_FIELD_RULE}
                >
                  <Input placeholder="State" size="large" />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  label="Place"
                  name='next_place'
                  rules={MANDATORY_FIELD_RULE}
                >
                  <Input placeholder="Place" size="large" />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Col xs={24}>
            <div className="steps-action">
              <Button style={{ marginRight: 8 }} onClick={() => this.prviousStep()} size="large">
                Previous
                </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                onClick={this.handleSubmit}
              >
                Next
                </Button>
            </div>
          </Col>
        </Form>
      </div >
    )
  }
}

export default CFormDetails;