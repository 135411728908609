import React from 'react';
import { Alert } from 'antd';

const FormErrorsList = (props) => {
  return (
    <Alert
      message="Something went wrong"
      description={
        <ul>
        {
          props.errors.map((error) => {
            return (
              <li key={error}> Invalid value for { error }</li>
            )
          })
        }
      </ul>
      }
      type="error"
      showIcon
    />
  )
}

export default FormErrorsList;