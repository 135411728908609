import React from 'reactn';
import { Select, Form, Input, Button } from 'antd';
import { ADDRESS_TAB_ID, BASIC_TAB_ID, MANDATORY_FIELD_RULE, ACCEPTED_ID_INDIA } from '../../utils/constants';
import { validateIdNumber, validateFileExtension } from '../../utils/GlobalFunctions';

class IdProofDetails extends React.Component {

  idProofForm = React.createRef()

  validateFiles = (values) => {
    let kyc_front = document.getElementById('kyc_front').files[0];
    if (!validateFileExtension(kyc_front)) {
      
      this.idProofForm.current.setFields([
        {
          name: 'kyc_front',
          errors: ['Invalid File format']
        }
      ]);
      
      return false;
    }

    let kyc_back = document.getElementById('kyc_back').files[0];
    if (!validateFileExtension(kyc_back)) {

      this.idProofForm.current.setFields([
        {
          name: 'kyc_back',
          errors: ['Invalid File format']
        }
      ]);
      
      return false;
    }
    return true;
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.idProofForm.current.validateFields().then(values => {
      
      if (validateIdNumber(values.kyc_type, values.kyc_number) === false) {
        this.idProofForm.current.setFields([
          {
            name: 'kyc_number',
            errors: ['Please enter a valid ID number']
          }
        ]);
        
      } else if (!this.validateFiles(values)) {
      } else {
        this.setGlobal({
          activeTab: ADDRESS_TAB_ID,
          idProofDetails: values
        })
      }
    }).catch(errInfo => {
      console.log(errInfo)
    });
  }
  prviousStep = () => {
    this.setGlobal({
      activeTab: BASIC_TAB_ID,
    })
  }
  render() {

    return (
      <div className="id-proof-container">
        <Form ref={this.idProofForm}>
          <Form.Item
            label="Type of ID Proof"
            name='kyc_type'
            rules={MANDATORY_FIELD_RULE}
          >

            <Select
              style={{ width: '100%' }}
              placeholder="Select ID Proof"
              size="large"
              onChange={this.handleIdChange}
            >
              {ACCEPTED_ID_INDIA.map(item => (
                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>

          </Form.Item>

          <Form.Item
            label="Enter ID Number"
            extra="Please enter your ID number without spaces or hyphens (-), if any"
            name='kyc_number'
            rules={MANDATORY_FIELD_RULE}
          >
            <Input placeholder="Enter ID Number" size="large" />
          </Form.Item>

          <Form.Item
            label="Front side of ID"
            name='kyc_front'
            rules={MANDATORY_FIELD_RULE}
          >
            <input id="kyc_front" type="file" size="large"
              accept="image/png, image/jpeg, image/jpg"
            />
          </Form.Item>

          <Form.Item
            label="Back side of ID"
            name='kyc_back'
            rules={MANDATORY_FIELD_RULE}
          >
            <input id="kyc_back" type="file" size="default"
              accept="image/png, image/jpeg, image/jpg"
            />

          </Form.Item>
          <div className="steps-action">
            <Button style={{ marginRight: 8 }} onClick={() => this.prviousStep()} size="large">
              Previous
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              onClick={this.handleSubmit}
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}


export default IdProofDetails;