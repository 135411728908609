import React from 'reactn';
import { Form, Input, Button, Row, Col, message, Spin, Alert } from 'antd';
import { submitUserData } from '../../utils/Api';
import { API_SUCCESS_IDENTIFIER, BASIC_TAB_ID } from '../../utils/constants';
import { limitInputLength, allowOnlyNumbers, validatePhoneNumber } from '../../utils/GlobalFunctions';


class MobileVerificationForm extends React.Component {

  loginFormRef = React.createRef();

  state = {
    otpSend: false,
    loading: false,
    generalError: '',
  }
  
  validateOtp(values) {
    if (values && (values.otp.length !== 6)) {
      this.loginFormRef.current.setFields([
        {
          name: 'otp',
          errors: ['Invalid OTP']
        }
      ]);
      return false;
    }
    return true;
  }
  sendOtp = () => {
    
    this.loginFormRef.current.validateFields(['mobile_number', 'email_id']).then(values => {
      
      if (validatePhoneNumber(values.mobile_number)) {
        this.setState({
          loading: true
        })
        var data = {
          'actual_data': JSON.stringify({ 
            mobile_number: values.mobile_number,
            email_id: values.email_id 
          })
        }
        submitUserData(data).then((response) => {
          
          if (response.data.status === API_SUCCESS_IDENTIFIER) {
            this.setState({
              otpSend: true,
            })
            message.success(response.data.message);
            this.loginFormRef.current.setFields([
              {
                name: 'otp',
                value: ['']
              }
            ]);
          } else { // if failed to sent otp
            this.loginFormRef.current.setFields([
              {
                name: 'mobile_number',
                errors: [response.data.message]
              }
            ]);
          }
        }).finally(() => {
          this.setState({ loading: false })
        })
      } else {
        // invalid mobile number
        this.loginFormRef.current.setFields([
          {
            name: 'mobile_number',
            errors: ['Invalid mobile number']
          }
        ]);
      }
    }).catch(errorInfo => {
      console.log(errorInfo)
    });
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.loginFormRef.current.validateFields().then(values => {
      if (validatePhoneNumber(values) && this.validateOtp(values)) {
        const actual_data = {
          'actual_data': JSON.stringify(values)
        };
        this.setState({
          loading: true,
        })
        submitUserData(actual_data).then((response) => {
          
          if (response.data.status === API_SUCCESS_IDENTIFIER) {
            
            this.setGlobal({
              verifiedMobileNumber: values.mobile_number,
              verifiedEmail: values.email_id,
              activeTab: BASIC_TAB_ID,
              verification_code: response.data.verification_code,
              isMobileOtpVerified: true,
              dropDownData: response.data.drop_down_data
            })
          } else {
            if (response.data.message.includes('Mobile')) {
              this.loginFormRef.current.setFields([
                {
                  name: 'mobile_number',
                  errors: [response.data.message]
                }
              ]);

              this.setState({
                generalError: ''
              })

            } else if (response.data.message.includes('OTP')) {
              this.loginFormRef.current.setFields([
                {
                  name: 'otp',
                  errors: [response.data.message]
                }
              ]);
              
              this.setState({
                generalError: ''
              })
            } else {

              this.setState({
                generalError: response.data.message
              })

            }
          }
        }).finally(() => {
          this.setState({ loading: false })
        })
      }
    }).catch(errInfo => {
      console.log(errInfo)
    });
  }
  render() {

    return (
      <div className="mobile-verification-container">
        <Spin spinning={this.state.loading} tip="Please wait...">
          <Form ref={this.loginFormRef}>
            <Row>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Phone Number"
                  name='mobile_number'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Phone Number',
                    }]}
                >
                  <Input 
                    placeholder="Phone Number"
                    size="large"
                    onKeyDown={(e) => allowOnlyNumbers(e)}
                    autoFocus
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={24}>
                <Form.Item
                  label="Email Address"
                  name='email_id'
                  rules={[
                    {
                      type: 'email',
                      message: 'Enter Email address'
                    },
                    {
                      required: true,
                      message: 'Enter Email address',
                    }]}
                >
                  <Input 
                    placeholder="Email Address"
                    size="large"
                    className="email"
                  />
                </Form.Item>
              </Col>

              {
                this.state.otpSend && (
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="OTP"
                      name='otp'
                      rules={[
                        {
                          required: true,
                          message: 'Enter 6 digit OTP',
                        }]}
                    >
                      <Input type="tel" placeholder="6 digit OTP"
                        size="large"
                        autoFocus={true}
                        onKeyDown={(e) => limitInputLength(6, e)}
                      />
                    </Form.Item>
                  </Col>
                )
              }
            </Row>
            <div style={{ width: '100%', marginTop: '15px' }}>
              <Button
                type={this.state.otpSend ? 'secondary' : 'primary'}
                htmlType="button"
                size="large"
                onClick={this.sendOtp}
                disabled={this.state.loading}
              >
                {this.state.otpSend ? 'Resend OTP' : 'Send OTP'}
              </Button>
              {
                this.state.otpSend && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={this.handleSubmit}
                    style={{ marginLeft: 10 }}
                    disabled={this.state.loading}
                  >
                    Submit
                  </Button>
                )
              }
            </div>
          </Form>
        </Spin>
        {
          this.state.generalError !== '' && (
            <Alert
              style={{ marginTop: '20px' }}
              message={this.state.generalError}
              type="error"
              showIcon
            />
          )
        }
      </div>
    )
  }
}

export default MobileVerificationForm;