import React from "react";
import Logo from "../assets/images/logo-housr.png";

const Header = (props) => {
  return (
    <>
      <div className="header">
        <div className="logoImage">
          <img src={Logo} alt="logoHousr" />
        </div>
        <div className="content">
          <h2>{props.heading}</h2>
          <p>{props.description}</p>
        </div>
      </div>
    </>
  );
};

export default Header;
