import React from 'react';

const TopBanner = () => {
  return (
    <div className="top-banner">
      <picture>
        <source media="(min-width: 701px)" srcSet={require('../assets/images/booking-form@2x.jpg')} />
        <source media="(max-width: 700px)" srcSet={require('../assets/images/booking-form@1x.jpg')} />
        <img src={require('../assets/images/booking-form@1x.jpg')} alt="stayabode resident form"/>
      </picture>
      <div id="color-overlay"></div>
    </div>
  )
}
export default TopBanner;